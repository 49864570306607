@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang&family=Gowun+Dodum&display=swap');
*{
  font-family: Gowun Dodum;
}
html{
  width: 100%;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

h1{
  font-family: Gowun Batang;
}
body{
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}



